import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';
import { useOrderContext } from '../../../OrderContext';
import { useModalContext } from '../../../ModalContext';
import { useAvailabilityContext } from '../../Context';
import { pushAvailability } from '../../../../utils/dataLayer/availability';
import {
  AvailabilityStatus,
  DataLayerEvents,
} from '../../../../interfaces/tracking';
import { ProductSelector } from '../../Elements/ProductSelector';
import styles from './OptionSelect.module.scss';
import { IconAttention, Spacer, Text } from 'dss-ui-library';
import { FTTHPlannedFallback } from '../FTTHPlannedFallback';
import { AddressRow } from '../../Elements/AddressRow';
import { hasCableTV } from '../../../../utils/promotion';
import { checkMandantType, getEnv } from '@ncs-frontend-monorepo/utils';
import {
  ExpansionStatus,
  Technology,
} from '@ncs-frontend-monorepo/availability';

const FibreOpticExpansion = dynamic(() =>
  import('../../Elements/FibreOpticExpansion').then(
    ({ FibreOpticExpansion }) => FibreOpticExpansion,
  ),
);

export interface AvailabilityOptionSelectProps {
  onChangeAddressClick?(): void;
  isEmbedded?: boolean;
}

const AvailabilityOptionSelect: React.FC<AvailabilityOptionSelectProps> = ({
  onChangeAddressClick,
  isEmbedded = false,
}) => {
  const {
    maxAvailableDownload,
    plannedAvailability,
    availableTv,
    promotions,
    isYoung,
  } = useOrderContext();
  const { closeModal } = useModalContext();
  const { availabilityInfo, availabilityType, templateId, manualCheck } =
    useAvailabilityContext();
  const {
    address,
    promotions: { presalePromotion },
    objectInformation,
    isFTTHPlanned,
  } = availabilityInfo;

  const mandantType = checkMandantType(getEnv().SITE, address?.mandant);

  const { eventLinks, eventResult } = pushAvailability();
  const { zipCode } = address;
  const isConnected = maxAvailableDownload > 0;
  const availabilityStatus = presalePromotion
    ? isConnected
      ? AvailabilityStatus.AvailablePresale
      : AvailabilityStatus.PresaleOnly
    : AvailabilityStatus.Available;

  const availablePromotion = promotions?.find(
    (promotion) => promotion.maxDownload === maxAvailableDownload,
  );

  const portfolioName =
    availablePromotion?.portfolio === 'FIBER' ? 'Glasfaser' : 'DSL/Kabel' || '';

  const isCableTV = !isYoung && hasCableTV(availableTv || []);

  const handleButtonClick = (targetUrl: string) => {
    eventLinks({
      event: DataLayerEvents.PlanLink,
      status: availabilityStatus,
      zipCode: address.zipCode,
      checkSource: availabilityType,
      maxDownload: maxAvailableDownload,
      targetPage: targetUrl,
    });
    closeModal();
  };

  const e2e = plannedAvailability
    ? `Presale-${plannedAvailability.maxAvailableDownload}-${
        presalePromotion?.technology
      }-${maxAvailableDownload}-0-${plannedAvailability.plannedAvailabilityDateDescription.replace(
        ' ',
        '',
      )}-${zipCode}-0-presaleavailable`
    : `${maxAvailableDownload}-0-${zipCode}`;

  useEffect(() => {
    eventResult({
      event:
        mandantType !== 'match'
          ? DataLayerEvents.ResultMandantMismatch
          : DataLayerEvents.Result,
      status: availabilityStatus,
      expansionStatus: objectInformation?.status || null,
      checkSource: availabilityType,
      checkStatus: manualCheck ? 'nicht-geprueft' : 'bereits-geprueft',
      zipCode: address.zipCode,
      maxDownload: maxAvailableDownload,
      presaleAvailability: !!plannedAvailability,
      ...(plannedAvailability && {
        plannedAvailabilityDate:
          plannedAvailability.plannedAvailabilityDateDescription,
      }),
      ...(availableTv && { tvPromotions: availableTv }),
      wholebuy: objectInformation?.wholebuy || null,
    });
  }, [mandantType]);

  const isFTTHOnly = isFTTHPlanned && !isConnected;
  const hasGfastPromotion =
    presalePromotion?.technology === Technology.GFAST &&
    availabilityInfo?.objectInformation?.status ===
      ExpansionStatus.HOMES_PREPARED;

  const PlannedAvailabilityText = () => {
    let text = (
      <>
        Bis voraussichtlich{' '}
        <span className={styles.strong}>
          {plannedAvailability.plannedAvailabilityDateDescription}
        </span>{' '}
        ist der Glasfaser-Ausbau an deiner Adresse abgeschlossen.{' '}
        {isConnected ? 'Dann' : 'Nach Abschluss'} kannst du glasfaserschnell mit
        bis zu{' '}
        <span className={styles.strong}>
          {plannedAvailability.maxAvailableDownload} Mbit/s
        </span>{' '}
        surfen.
      </>
    );

    if (hasGfastPromotion) {
      text = (
        <>
          Der Glasfaserausbau ist bereits abgeschlossen. Wenn du jetzt
          bestellst, kannst du ab{' '}
          <span className={styles.strong}>
            {plannedAvailability.plannedAvailabilityDateDescription}
          </span>{' '}
          glasfaserschnell mit bis zu{' '}
          <span className={styles.strong}>
            {plannedAvailability.maxAvailableDownload} Mbit/s
          </span>{' '}
          surfen.
        </>
      );
    }

    return (
      <Text
        e2e="available-fibre-optics-promotion-info-text"
        appearance="t4"
        block
      >
        {text}
      </Text>
    );
  };

  return isFTTHOnly ? (
    <FTTHPlannedFallback
      address={address}
      hasPreselectedProduct={!!templateId}
      onChangeAddressClick={onChangeAddressClick}
    />
  ) : (
    <div id={`ac-${isEmbedded ? 'content' : 'ot'}-result`} data-e2e={e2e}>
      <Spacer b={1} r={4} block>
        <Text appearance="t2" color="blue">
          {isConnected
            ? 'Verfügbare Produkte an deiner Adresse'
            : 'Demnächst verfügbare Produkte an deiner Adresse'}
        </Text>
      </Spacer>
      <AddressRow
        address={address}
        onChangeAddressClick={onChangeAddressClick}
      />
      {isFTTHPlanned ? (
        <>
          <Spacer b={3} block>
            <FibreOpticExpansion
              buttonColor="blue"
              displayStyle="nextto"
              objectInformation={objectInformation}
              embedded={isEmbedded}
            />
          </Spacer>
          <Text appearance="t2_2">Jetzt bestellbar</Text>
          <Spacer t={1}>
            <Text e2e="available-promotion-info-text" appearance="t4">
              An deiner Adresse surfst du aktuell mit bis zu{' '}
              <span className={styles.strong}>
                {maxAvailableDownload} Mbit/s {portfolioName}
              </span>
              . Du willst noch mehr? Dann hol dir NetTV über Internet und die
              Telefon-Flat dazu.
            </Text>
          </Spacer>
        </>
      ) : (
        <Text e2e="available-promotion-info-text" appearance="t4">
          {isConnected && (
            <>
              An deiner Adresse surfst du aktuell mit bis zu{' '}
              <span className={styles.strong}>
                {maxAvailableDownload} Mbit/s {portfolioName}
              </span>
              .
            </>
          )}
          {plannedAvailability && (
            <Spacer y={1} className={styles.infoText}>
              <Spacer r={2}>
                <IconAttention color="blue" width={24} height={24} />
              </Spacer>
              <PlannedAvailabilityText />
            </Spacer>
          )}{' '}
          {isConnected &&
            `Du willst noch mehr? Dann hol dir die Telefon-Flat und NetTV über ${
              isCableTV ? 'Kabel' : 'Internet'
            } dazu.`}
        </Text>
      )}
      <Spacer b={3} block />
      <ProductSelector
        e2e="ac"
        isEmbedded={isEmbedded}
        onPlanClick={handleButtonClick}
        portfolio={
          plannedAvailability
            ? 'FIBER'
            : availablePromotion?.portfolio || 'FIBER'
        }
      />
    </div>
  );
};

export { AvailabilityOptionSelect };
