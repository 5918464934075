import React, { useEffect } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  IconNetSpeed1000,
  IconNetTV,
  IconTelevision,
  IconPhone,
  Spacer,
  Text,
} from 'dss-ui-library';
import { useOrderContext } from '../../../OrderContext';
import { useProductSelector } from './useProductSelector';
import { Portfolio } from '../../Context';
import styles from './ProductSelector.module.scss';
import cx from 'classnames';
import { ChevronLink } from '@ncs-frontend-monorepo/content-library';
import { buildPlanOverviewUrl } from '../../../../utils/plan';
import { useRouter } from 'next/router';
import { hasCableTV } from '../../../../utils/promotion';
import { useYoungStore } from '../../../../store/youngStore';

export interface ProductSelectorProps {
  onPlanClick?(path: string): void;
  e2e: string;
  isEmbedded?: boolean;
  portfolio?: Portfolio;
  showYoungSelection?: boolean;
}

export type SelectionItem = 'Phone' | 'NetTv' | 'Internet';

export const ProductSelector: React.FC<ProductSelectorProps> = ({
  e2e,
  isEmbedded = false,
  portfolio = 'FIBER',
  onPlanClick,
  showYoungSelection = true,
}) => {
  const {
    maxAvailableDownload,
    plannedAvailability,
    setIsYoung,
    isYoung,
    availableTv,
  } = useOrderContext();
  const maxBandwidth =
    plannedAvailability && maxAvailableDownload === 0
      ? plannedAvailability.maxAvailableDownload
      : maxAvailableDownload;

  const {
    selectionText,
    selectionURL,
    phoneEnabled,
    tvEnabled,
    toggleSelection,
    modifier,
  } = useProductSelector({
    isYoung: !showYoungSelection ? false : isYoung,
    portfolio,
    availableTv,
  });
  const router = useRouter();
  const isCableTV = !isYoung && hasCableTV(availableTv || []);

  const isAvailableWithPresale =
    plannedAvailability?.maxAvailableDownload > 0 && maxAvailableDownload > 0;

  const handleButtonClick = () => {
    onPlanClick && onPlanClick(selectionURL);
    router.push(selectionURL);
  };

  const isYoungChecked = useYoungStore((state) => state.isYoungChecked);

  useEffect(() => {
    if (isYoungChecked !== '' && isYoungChecked !== undefined) {
      setIsYoung(true);
    }
  }, [isYoungChecked]);

  return (
    <Grid fullWidth>
      <Grid.Row>
        <Grid.Column s={4} className={styles.col}>
          <div className={cx(styles.wrapper, styles.checked, styles.noHover)}>
            <Spacer y={1}>
              <IconNetSpeed1000
                color="white"
                className={cx(styles.iconNetSpeed, styles.checked)}
              />
            </Spacer>
            <span className={styles.text}>
              <Text appearance="t4_2" block>
                Internet
              </Text>
              <Text appearance="t4">
                Internet-Flat mit bis zu {maxBandwidth} Mbit/s
              </Text>
            </span>
          </div>
        </Grid.Column>
        <Grid.Column s={4} className={styles.col}>
          <div
            className={cx(styles.wrapper, {
              [styles.checked]: phoneEnabled,
              [styles.embedded]: isEmbedded,
            })}
            onClick={() => {
              toggleSelection('Phone');
            }}
            data-e2e={`${e2e}-ot-phone-selection`}
          >
            <Spacer b={1}>
              <IconPhone
                color={phoneEnabled ? 'white' : 'blue'}
                className={styles.iconOption}
              />
            </Spacer>
            <Checkbox
              checked={phoneEnabled}
              background={phoneEnabled ? 'blue' : 'white'}
              name="phone"
              e2e={`${e2e}-phone`}
              label={
                <span className={styles.text}>
                  <Text appearance="t4_2" block>
                    Telefon
                  </Text>
                  <Text appearance="t4">Flat ins gesamte deutsche Netz</Text>
                </span>
              }
            />
          </div>
        </Grid.Column>
        <Grid.Column s={4} className={styles.col}>
          <div
            className={cx(styles.wrapper, {
              [styles.checked]: tvEnabled,
              [styles.embedded]: isEmbedded,
            })}
            onClick={() => {
              toggleSelection('NetTv');
            }}
            data-e2e={`${e2e}-ot-nettv-selection`}
          >
            <Spacer b={1}>
              {isCableTV ? (
                <IconTelevision
                  color={tvEnabled ? 'white' : 'blue'}
                  className={styles.iconOption}
                />
              ) : (
                <IconNetTV
                  color={tvEnabled ? 'white' : 'blue'}
                  className={styles.iconOption}
                />
              )}
            </Spacer>
            <Checkbox
              checked={tvEnabled}
              background={tvEnabled ? 'blue' : 'white'}
              name="nettv"
              e2e={`${e2e}-nettv`}
              label={
                <span className={styles.text}>
                  <Text appearance="t4_2" block>
                    Fernsehen
                  </Text>
                  <Text appearance="t4">
                    NetTV über {isCableTV ? 'Kabel' : 'Internet'}
                  </Text>
                </span>
              }
            />
          </div>
        </Grid.Column>
        {showYoungSelection && (
          <Grid.Column>
            <Spacer b={2} block />
            <Checkbox
              label="Ich bin Student oder Azubi"
              name="isYoung"
              e2e="isYoung"
              checked={isYoung}
              onChange={() => setIsYoung(!isYoung)}
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Spacer b={3} />
      <Grid.Row>
        <Grid.Column s={6} m={7} vCenter>
          <Text appearance="t4" block>
            Deine Auswahl:
          </Text>
          <Text appearance="t4_2" color="blue" e2e="ac-ot-selection">
            {selectionText}
          </Text>
          <Spacer b={1} block />
        </Grid.Column>
        <Grid.Column s={6} m={5}>
          <Button
            fullWidth
            color="red"
            e2e="ac-ot-to-plan-overview"
            variant="primary"
            onClick={handleButtonClick}
          >
            {portfolio === 'FIBER' || isAvailableWithPresale
              ? 'Zu den Glasfaser-Tarifen'
              : 'Zu den DSL/Kabel-Tarifen'}
          </Button>
        </Grid.Column>
      </Grid.Row>
      {isAvailableWithPresale && (
        <Grid.Row>
          <Grid.Column hEndS>
            <Spacer t={2} block>
              <ChevronLink
                e2e="ac-ot-to-available-plans"
                appearance="t5_2"
                url={buildPlanOverviewUrl({
                  isYoung: isCableTV ? false : isYoung,
                  portfolio: 'CLASSIC',
                  productModifier: modifier,
                })}
              >
                Zu den DSL/Kabel-Tarifen
              </ChevronLink>
            </Spacer>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};
