import { SelectionItem } from './index';
import { useMemo } from 'react';
import { Portfolio } from '../../Context';
import { buildPlanOverviewUrl } from '../../../../utils/plan';
import { ProductModifiers } from '../../../../interfaces/product';
import { OrderState } from '../../../OrderContext';
import { hasCableTV } from '../../../../utils/promotion';
import { useOrderStore } from '@ncs-frontend-monorepo/order';

interface UseProductSelector {
  isYoung: boolean;
  maxBandwidth?: number;
  portfolio?: Portfolio;
  availableTv?: OrderState['availableTv'];
}

export const useProductSelector = ({
  isYoung = false,
  portfolio = 'FIBER',
  availableTv,
}: UseProductSelector) => {
  const { modifier, updateModifier, resetModifier } = useOrderStore();
  const isCableTV = !isYoung && hasCableTV(availableTv || []);

  const phoneEnabled =
    modifier !== ProductModifiers.withoutAll &&
    modifier !== ProductModifiers.withoutPhone;
  const tvEnabled =
    modifier !== ProductModifiers.withoutAll &&
    modifier !== ProductModifiers.withoutTv;

  const selectionText = `Internet-Flat${phoneEnabled ? ' + Telefon' : ''}
  ${tvEnabled ? ` + NetTV über ${isCableTV ? 'Kabel' : 'Internet'}` : ''}`;

  const selectionURL = useMemo(
    () =>
      buildPlanOverviewUrl({
        isYoung: isCableTV && tvEnabled ? false : isYoung,
        portfolio,
        productModifier: modifier,
        isCableTV,
      }),
    [isYoung, portfolio, modifier],
  );

  const toggleSelection = (item: SelectionItem) => {
    switch (true) {
      case !phoneEnabled && !tvEnabled:
        updateModifier(
          item === 'Phone'
            ? ProductModifiers.withoutTv
            : ProductModifiers.withoutPhone,
        );
        break;
      case phoneEnabled && tvEnabled:
        updateModifier(
          item === 'Phone'
            ? ProductModifiers.withoutPhone
            : ProductModifiers.withoutTv,
        );
        break;
      case tvEnabled:
        updateModifier(
          item === 'Phone'
            ? ProductModifiers.withoutModifier
            : ProductModifiers.withoutAll,
        );
        break;
      case phoneEnabled:
        updateModifier(
          item === 'Phone'
            ? ProductModifiers.withoutAll
            : ProductModifiers.withoutModifier,
        );
        break;
      default:
        resetModifier();
        break;
    }
  };

  return {
    selectionText,
    selectionURL,
    toggleSelection,
    modifier,
    phoneEnabled,
    tvEnabled,
  };
};
